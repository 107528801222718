// vendors
import React from 'react';

// images
import LogoPiedBleu from '../../images/logo__le-pied-bleu.svg';

// styles
import { Container, Wrapper, List, ListItem, Link } from './Footer.styles';

const Footer = () => (
  <Container>
    <Wrapper>
      <List>
        <ListItem>
          <Link
            href='https://www.facebook.com/RoiDuBoudin/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Facebook
          </Link>
        </ListItem>

        <ListItem>
          <Link
            href='https://www.instagram.com/explore/tags/lepiedbleu/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Instagram
          </Link>
        </ListItem>
      </List>

      <Link href='mailto:lepatron@piedbleu.com'>lepatron@piedbleu.com</Link>

      <div className=''>
        <img src={LogoPiedBleu} alt='Le pied bleu' />
      </div>
    </Wrapper>
  </Container>
);

export default Footer;
