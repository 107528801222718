// vendors
import React from 'react';
import styled from 'styled-components';

// images
import LogoBouillon from '@images/LogoBouillon';

const Tag = styled.h1`
  display: block;
  margin: 0;
  padding: 0;

  text-align: center;
`;

const Logo = (props) => {
  return (
    <Tag {...props}>
      <LogoBouillon primary />
    </Tag>
  );
};

export default Logo;
