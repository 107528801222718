// vendors
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import Header from '@components/Header';

// styles
import '@pied-bleu/shared-ui/src/fonts/fonts.css';
import '@csstools/normalize.css';
import 'sanitize.css';
import 'sanitize.css/typography.css';

import {
  styles,
  Footer,
  MetaNavigation,
  SubNavigation,
} from '@pied-bleu/shared-ui';

const Link = styled.a`
  ${styles.global.linkStyle};

  color: inherit;
`;

const Layout = ({ children }) => {
  return (
    <>
      <styles.global.GlobalStyle />

      <MetaNavigation />

      <SubNavigation inverted>
        <Link
          href='https://www.doordash.com/business/587156/?utm_source=partner-link&utm_medium=website&utm_campaign=587156&utm_content=red-m'
          target='_blank'
          rel='noopener noreferrer'
          style={{ transitionDelay: '0ms' }}
        >
          Commande pour livraison
        </Link>

        <span>
          Commande pour emporter{'  '}
          <Link
            href='tel:+14189143554'
            style={{
              fontWeight: styles.typography.fontWeights.bold,
              transitionDelay: '100ms',
            }}
          >
            418 914-3554
          </Link>
        </span>
      </SubNavigation>

      <Header />

      <main>{children}</main>

      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
