// vendors
import React from 'react';
import PropTypes from 'prop-types';

// styles
import colors from '../../styles/colors';
import { Container, Wrapper } from './SectionContainer.styles';

const SectionContainer = ({
  tag,
  noWrap,
  bgColor,
  borderPatternTop,
  borderPatternBottom,
  children,
  ...rest
}) => (
  <Container
    as={tag}
    $bgColor={bgColor}
    $borderPatternTop={borderPatternTop}
    $borderPatternBottom={borderPatternBottom}
    {...rest}
  >
    <Wrapper $noWrap={noWrap}>{children}</Wrapper>
  </Container>
);

SectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
  bgColor: PropTypes.string,
  noWrap: PropTypes.bool,
  borderPatternTop: PropTypes.bool,
  borderPatternBottom: PropTypes.bool,
};

SectionContainer.defaultProps = {
  tag: undefined,
  bgColor: colors.white,
  noWrap: false,
  borderPatternTop: false,
  borderPatternBottom: false,
};

export default SectionContainer;
