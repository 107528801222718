// vendors
import React from 'react';
import PropTypes from 'prop-types';

// utils
import randomString from '../../utils/math/randomString';

// styles
import {
  Container,
  Navigation,
  List,
  ListItem
} from './SubNavigation.styles';  

const SubNavigation = ({ inverted, children }) => {
  const items = React.Children.toArray(children);

  return (
    <Container $inverted={inverted}>
      <Navigation>
        <List>
          {items.map((item) => (
            <ListItem key={randomString()}>
              {item}
            </ListItem>
          ))}
        </List>
      </Navigation>
    </Container>
  );
}

SubNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  inverted: PropTypes.bool,
};
SubNavigation.defaultProps = {
  inverted: false,
};

export default SubNavigation;
