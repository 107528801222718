export default (day) => {
  const date = new Date();

  const delta = date.getDay() - Number(day);

  date.setDate(date.getDate() - delta);

  const locale =
    typeof window !== `undefined` ? window.navigator.language : 'fr-CA';

  return date.toLocaleString(locale, {
    weekday: 'long',
  });
};
