// vendors
import styled from 'styled-components';

// styles
import { center } from '../../styles/layouts';
import colors from '../../styles/colors';
import {
  linkStyle,
  unstyledLinkStyle,
  unorderListStyle,
} from '../../styles/global';

export const Container = styled.footer`
  position: relative;
  z-index: 6;

  padding: 50px 0;

  color: ${colors.primary};
  text-transform: uppercase;

  background-color: ${colors.secondary};
`;

export const Wrapper = styled.div`
  ${center('30px', '1590px')}

  font-size: 0.75rem;
  text-align: center;

  @media (min-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    text-align: left;
  }
`;

export const List = styled.ul`
  ${unorderListStyle}
`;

export const ListItem = styled.li``;

export const Link = styled.a`
  ${unstyledLinkStyle};
  ${linkStyle};

  display: block;
  margin: 8px 0;

  @media (min-width: 500px) {
    margin: 3px 0;
  }
`;
