// vendors
import styled, { css } from 'styled-components';

// styles
import { center } from '../../styles/layouts';
import colors from '../../styles/colors';

const patternStyle = css`
  background-color: ${colors.secondary};
  background-image: linear-gradient(
      45deg,
      ${colors.primary} 25%,
      transparent 0,
      transparent 75%,
      ${colors.primary} 0,
      ${colors.primary}
    ),
    linear-gradient(
      45deg,
      ${colors.primary} 25%,
      transparent 0,
      transparent 75%,
      ${colors.primary} 0,
      ${colors.primary}
    );
  background-position: -20px 0, 20px 40px;
  background-size: 81px 81px;
`;

const borderPatternTopStyle = css`
  padding-top: 0;

  ::before {
    display: block;
    height: 80px;
    margin-bottom: 8em;

    ${patternStyle}

    content: ' ';
  }
`;

const borderPatternBottomStyle = css`
  padding-bottom: 0;

  ::after {
    display: block;
    height: 80px;
    margin-top: 8em;

    ${patternStyle}

    content: ' ';
  }
`;

export const Container = styled.section`
  padding: 5em 0;

  background-color: ${({ $bgColor }) => $bgColor};

  ${({ $borderPatternTop }) => $borderPatternTop && borderPatternTopStyle};

  ${({ $borderPatternBottom }) =>
    $borderPatternBottom && borderPatternBottomStyle};
`;

export const Wrapper = styled.div`
  position: relative;

  ${center('30px', ({ $noWrap }) => ($noWrap ? `100%` : '1590px'))};
`;
