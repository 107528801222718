/**
 * Tell if array B of the array A
 * @param {[Number]} a Main array
 * @param {[Number]} b Array to evaluate if it is part of Array a
 */
const containOrEqualArray = (a, b) =>
  b.map((e) => a.includes(e)).every((e) => e);

export function groupBySequence(list) {
  return list.reduce((acc, current) => {
    if (acc.length < 1) {
      acc.push([current]);
      return acc;
    }

    const group = acc[acc.length - 1];
    const lastGroupItem = group[group.length - 1];

    if (current - lastGroupItem === 1) {
      group.push(current);
    } else {
      acc.push([current]);
    }

    return acc;
  }, []);
}

export default function (list) {
  let formattedDay = list.map((e) => Number(e)).sort((a, b) => a - b);

  formattedDay = [...formattedDay, ...formattedDay.map((e) => e + 7)];

  formattedDay = groupBySequence(formattedDay);

  formattedDay = formattedDay.map((d) => d.map((e) => e % 7));

  return formattedDay
    .sort((a, b) => a.length - b.length)
    .filter(
      (group, index, self) =>
        !self
          .slice(index + 1)
          .map((e) => containOrEqualArray(e, group))
          .some((e) => e)
    )
    .sort((a, b) => a[0] - b[0]);
}
