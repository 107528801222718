// vendors
import { css, createGlobalStyle } from 'styled-components';
import {rem} from 'polished';

import { fontFamilies, fontWeights, lineHeights } from './typography';
import { lessThan, greaterThan } from '../utils/mediaQuery';
import breakpointsRange from '../utils/breakpointsRange';
import colors from './colors';

export const rootStyles = css`
  /*  A = (18 - 16) / (1590 - 320)
      B = 18 - (((18 - 16) / (1590 - 320)) * 1590) - 1rem{16}
      calc(1rem + Avw + b) */
  --font-size: calc(1rem + 0.15748031vw - 0.50393701px);
  --line-height: ${lineHeights.body};
`;

export const htmlStyles = css`
  font-size: var(--font-size);
  line-height: var(--line-height);
  scroll-behavior: smooth;

  ${lessThan(480)} {
    font-size: ${rem(16)};
  }

  ${greaterThan(1590)} {
    font-size: ${rem(18)};
  }
`;

export const bodyStyles = css`
  font-family: ${fontFamilies.body};
  font-kerning: normal;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
`;

export const h1Style = css``;

export const h2Style = css``;

export const h3Style = css``;

export const h4Style = css``;

export const h5Style = css``;

export const h6Style = css``;

export const introStyle = css``;

export const unbreakableStringStyle = css`
  word-break: keep-all;
`;

export const unstyledLinkStyle = css`
  color: inherit;
  text-decoration: inherit;
`;

export const magnifyStyle = css`
  font-weight: ${fontWeights.bold};
  line-height: 1.4;
  text-transform: uppercase;

  ${breakpointsRange(
    [
      {
        prop: 'fontSize',
        sizes: [18, 40],
        bases: [16, 18],
        unit: 'rem',
      },
    ],
    [480, 1120]
  )};
`;

export const titleStyle = css``;

export const figCaptionStyle = css``;

export const linkStyle = css`
  ${unstyledLinkStyle};

  color: ${colors.white};

  @media (prefers-reduced-motion: no-preference) {
    transition: color 0.4s ease;
  }

  &:hover,
  &:focus {
    color: ${colors.primary};
  }
`;

export const externalLinkStyle = css``;

export const paragraphStyle = css``;

export const unstyledListStyle = css`
  margin: 0;
  padding: 0;

  list-style: none;
`;

export const unorderListStyle = css`
  ${unstyledListStyle};
`;

export const orderListStyle = css``;

export const GlobalStyle = createGlobalStyle`
  :root {
    ${rootStyles};
  }

  html {
    ${htmlStyles};
  }

  body {
    ${bodyStyles};
  }
`;
