export const fontFamilies = {
  body: "'pressura', sans-serif",
  heading: 'inherit',
};

export const fontWeights = {
  regular: 300,
  bold: 700,
};

export const lineHeights = {
  body: 1.5,
  heading: 1.15,
};
