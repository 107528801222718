// vendors
import React from 'react';

// styles
import {
  Container,
  Wrapper,
  Content,
  IntroWrapper,
  LogoWrapper,
  Logo,
  TextWrapper,
} from './Header.styles';

const Header = () => {
  return (
    <Container>
      <Wrapper>
        <Content>
          <IntroWrapper>
            <p
              css={`
                text-transform: uppercase;
                letter-spacing: 5px;
              `}
            >
              Le Pied Bleu
            </p>

            <p
              css={`
                font-size: 2.75em;
                line-height: 1.2;
              `}
            >
              s&apos;invite à chez VOUS!
            </p>
          </IntroWrapper>

          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <TextWrapper>
            <p>Service Express à petit prix.</p>
            <p>Pour emporter ou pour livraison.</p>
            <p>À la maison ou en réunion.</p>
          </TextWrapper>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Header;
