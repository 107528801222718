import React from 'react';
import PropTypes from 'prop-types';

import { styles } from '@pied-bleu/shared-ui';

const LogoBouillon = ({ primary, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 440 440' {...rest}>
    <g>
      <circle
        cx='220'
        cy='220'
        r='220'
        fill={primary ? styles.colors.primary : styles.colors.secondary}
      />
      <g>
        <path
          d='M93.14,186.66a1.28,1.28,0,0,0,1.77-.73l11-26.14a2.7,2.7,0,0,1,3.53-1.44l12.45,5.24a2.72,2.72,0,0,1,1.45,3.54l-17.81,42.32a2.71,2.71,0,0,1-3.54,1.44L19.82,176.32a2.71,2.71,0,0,1-1.44-3.54l5.24-12.44a2.72,2.72,0,0,1,3.54-1.45Z'
          fill='#fff'
        />
        <path
          d='M128.87,128.8a1.28,1.28,0,0,0,1.89.29l25.78-19a2.71,2.71,0,0,1,3.77.57l8,10.88a2.72,2.72,0,0,1-.57,3.78l-39.93,29.38a2.7,2.7,0,0,1-3.77-.57L71.2,82.35a2.7,2.7,0,0,1,.58-3.77l39.48-29.07a2.71,2.71,0,0,1,3.78.58l8,10.87a2.71,2.71,0,0,1-.58,3.78L97.13,83.4a1.28,1.28,0,0,0-.29,1.89l9.61,13a1.28,1.28,0,0,0,1.89.29l24.47-18a2.71,2.71,0,0,1,3.78.58l8,10.87a2.71,2.71,0,0,1-.58,3.78l-24.47,18a1.26,1.26,0,0,0-.29,1.88Z'
          fill='#fff'
        />
        <path
          d='M255.86,89.38a26.38,26.38,0,0,1,3.5,18,1.52,1.52,0,0,0,1.32,2c5.8.74,12.94,5.29,16.69,11.06,9.64,14.84,6.61,30.5-7.32,39.55l-26.73,17.37a2.71,2.71,0,0,1-3.74-.8L191,101.87a2.71,2.71,0,0,1,.79-3.74l26.73-17.36C231.23,72.52,247.55,76.58,255.86,89.38Zm-19.57,45.73a1.28,1.28,0,0,0-.4,1.87l9.64,14.84a1.27,1.27,0,0,0,1.87.39l11.78-7.65c4.87-3.16,5.28-9.23,2.34-13.76s-8.69-6.43-13.45-3.34Zm1.48-23.51a9.06,9.06,0,0,0,2.69-12.22A8.94,8.94,0,0,0,228.28,97l-11.78,7.65a1.28,1.28,0,0,0-.4,1.87l8,12.35a1.27,1.27,0,0,0,1.87.39Z'
          fill='#fff'
        />
        <path
          d='M347,69.85a177.51,177.51,0,0,1,2.26,37.76c-.92,16.29-11.7,30.6-30.31,31.71s-31-11.8-33.87-27.87a176.72,176.72,0,0,1-2.26-37.75c.92-16.29,11.7-30.61,30.3-31.72S344.15,53.79,347,69.85Zm-16.75,38.89A176.81,176.81,0,0,0,328,71c-1-5.89-5.5-10.49-13.72-10s-12.11,5.6-12.43,11.57a176.72,176.72,0,0,0,2.26,37.75c1,5.89,5.5,10.5,13.73,10S329.94,114.72,330.26,108.74Z'
          fill='#fff'
        />
        <path
          d='M344.9,214.87c-16.11,2.71-31.67-8.37-34.76-26.76-3-18.11,8-33.67,24.16-36.38l62.6-10.51a2.7,2.7,0,0,1,3.11,2.22l2.26,13.45a2.7,2.7,0,0,1-2.21,3.11l-62.61,10.52c-5.86,1-9.85,6.58-8.53,14.44,1.37,8.12,7,12.11,12.83,11.13l62.6-10.51a2.7,2.7,0,0,1,3.11,2.21l2.26,13.46a2.71,2.71,0,0,1-2.21,3.11Z'
          fill='#fff'
        />
        <path
          d='M343.19,287.09a2.7,2.7,0,0,1-3.79-.43L331,276.1a2.7,2.7,0,0,1,.43-3.79l69.69-55.59a2.7,2.7,0,0,1,3.79.42l8.43,10.56a2.72,2.72,0,0,1-.43,3.8Z'
          fill='#fff'
        />
        <path
          d='M306.11,298a1.27,1.27,0,0,0-1.88-.32l-23.14,16.4a2.7,2.7,0,0,1-3.76-.64l-7.82-11a2.71,2.71,0,0,1,.65-3.77l37.46-26.56a2.7,2.7,0,0,1,3.76.65l51.56,72.72a2.7,2.7,0,0,1-.64,3.76l-11,7.81a2.7,2.7,0,0,1-3.76-.64Z'
          fill='#fff'
        />
        <path
          d='M263.85,348.3a1.28,1.28,0,0,0-1.71-.85l-26.92,8.92a2.72,2.72,0,0,1-3.42-1.72l-4.25-12.82a2.72,2.72,0,0,1,1.72-3.41L272.86,324a2.7,2.7,0,0,1,3.41,1.71l28,84.61a2.71,2.71,0,0,1-1.71,3.42L289.78,418a2.73,2.73,0,0,1-3.42-1.72Z'
          fill='#fff'
        />
        <path
          d='M144.11,382.61A176.94,176.94,0,0,1,153.39,346c5.81-15.25,20.42-25.62,38.49-21.05s26,20.65,23.83,36.83a176.31,176.31,0,0,1-9.28,36.66C200.62,413.64,186,424,167.94,419.44S142,398.79,144.11,382.61Zm27.74-32a177.57,177.57,0,0,0-9.28,36.66c-.8,5.93,2.06,11.67,10.05,13.69s13.24-1.66,15.35-7.25a176.4,176.4,0,0,0,9.28-36.67c.81-5.92-2.06-11.66-10.05-13.69S174,345,171.85,350.62Z'
          fill='#fff'
        />
        <path
          d='M140.14,284.29a3.5,3.5,0,0,1-.76,4.24l-37.06,34.81c-.65.55,6.89,1.66,7.64,1.35l36.39-17.85a2.7,2.7,0,0,1,3.51,1.49l5.07,12.52a2.72,2.72,0,0,1-1.49,3.52L70.8,357.8a2.72,2.72,0,0,1-3.52-1.5l-6-14.9a3.33,3.33,0,0,1,1-4L102.88,301c.65-.55.05-1.33-.7-1L55.1,319a2.71,2.71,0,0,1-3.52-1.49L46.52,305A2.71,2.71,0,0,1,48,301.45L130.65,268a2.7,2.7,0,0,1,3.51,1.49Z'
          fill='#fff'
        />
      </g>
      <g>
        <path
          d='M163.11,29.26a2.18,2.18,0,0,1,1.22,4.18l-.9.27a.09.09,0,0,0-.07.13l.73,2.49a.22.22,0,0,1-.15.26l-1,.29a.21.21,0,0,1-.26-.15l-1.92-6.58a.22.22,0,0,1,.14-.26Zm-.49,1.65a.1.1,0,0,0-.07.13l.34,1.2a.1.1,0,0,0,.13.07l.9-.26a.73.73,0,0,0,.5-.9.75.75,0,0,0-.91-.5Z'
          fill='#fff'
        />
        <path
          d='M171.31,34.35a.21.21,0,0,1-.15.25l-1,.26a.21.21,0,0,1-.26-.15l-1.71-6.64a.23.23,0,0,1,.15-.26l1-.25a.2.2,0,0,1,.25.14Z'
          fill='#fff'
        />
        <path
          d='M175.72,31.91a.1.1,0,0,0,.12.08l2.4-.54a.2.2,0,0,1,.25.16l.23,1a.22.22,0,0,1-.16.25l-3.72.83a.2.2,0,0,1-.25-.16l-1.5-6.69a.22.22,0,0,1,.16-.25l3.68-.82a.2.2,0,0,1,.25.16l.23,1a.22.22,0,0,1-.16.25l-2.36.53a.09.09,0,0,0-.08.12l.27,1.22a.1.1,0,0,0,.12.08l2.29-.51a.19.19,0,0,1,.24.15l.23,1a.21.21,0,0,1-.16.25l-2.28.51a.1.1,0,0,0-.08.12Z'
          fill='#fff'
        />
        <path
          d='M182.87,24.62a2.42,2.42,0,0,1,2.69,1.85,11.27,11.27,0,0,1,.48,2.64,2.42,2.42,0,0,1-1.87,2.67l-2.15.39a.21.21,0,0,1-.24-.16l-1.22-6.76a.2.2,0,0,1,.16-.24Zm-.56,1.58a.1.1,0,0,0-.08.12l.74,4.09a.1.1,0,0,0,.12.09l.82-.15a.89.89,0,0,0,.69-1,11.27,11.27,0,0,0-.48-2.64.89.89,0,0,0-1-.68Z'
          fill='#fff'
        />
        <path
          d='M193.58,25.11a2,2,0,0,1-.34,1.37.12.12,0,0,0,0,.19,2.15,2.15,0,0,1-1,3.83l-2.42.34a.22.22,0,0,1-.24-.18l-.94-6.79a.21.21,0,0,1,.18-.24l2.42-.34A2.06,2.06,0,0,1,193.58,25.11Zm-2,1a.67.67,0,1,0-.19-1.33l-1.07.15a.11.11,0,0,0-.09.12l.16,1.12a.1.1,0,0,0,.12.09Zm-.87,1.59a.1.1,0,0,0-.09.12l.19,1.35c0,.06,0,.09.11.08l1.07-.14a.79.79,0,0,0-.21-1.56Z'
          fill='#fff'
        />
        <path
          d='M198.59,28.17a.09.09,0,0,0,.11.09l2.17-.22a.21.21,0,0,1,.23.19l.1,1a.21.21,0,0,1-.18.23l-3.52.36a.22.22,0,0,1-.23-.19l-.69-6.82a.21.21,0,0,1,.19-.23l1-.1a.21.21,0,0,1,.23.18Z'
          fill='#fff'
        />
        <path
          d='M205.38,27.6a.09.09,0,0,0,.11.09l2.45-.16a.21.21,0,0,1,.22.2l.07,1A.21.21,0,0,1,208,29l-3.81.24A.21.21,0,0,1,204,29l-.44-6.85a.21.21,0,0,1,.19-.22l3.77-.24a.21.21,0,0,1,.22.19l.07,1a.22.22,0,0,1-.2.22l-2.41.16c-.07,0-.1,0-.1.11l.08,1.24a.1.1,0,0,0,.11.1l2.33-.15a.2.2,0,0,1,.22.19l.07,1a.21.21,0,0,1-.19.22l-2.34.15a.09.09,0,0,0-.09.11Z'
          fill='#fff'
        />
        <path
          d='M216.08,26.54a2.47,2.47,0,0,1-4.92.12L211,21.78a.22.22,0,0,1,.2-.22l1,0a.2.2,0,0,1,.21.2l.12,4.88a.89.89,0,0,0,1,.81c.63,0,1-.4,1-.86l-.12-4.88a.2.2,0,0,1,.2-.21l1.05,0a.21.21,0,0,1,.21.2Z'
          fill='#fff'
        />
        <path
          d='M220.62,28.53a.2.2,0,0,1-.21.2h-1.24a.21.21,0,0,1-.21-.21V27.27a.21.21,0,0,1,.21-.21h1.25a.21.21,0,0,1,.2.21Z'
          fill='#fff'
        />
        <path
          d='M228.77,26.74A2.32,2.32,0,0,1,226.21,29a2.45,2.45,0,0,1-2.38-2.39A13.49,13.49,0,0,1,224,23.7a2.46,2.46,0,0,1,2.56-2.19A2.34,2.34,0,0,1,228.89,24h0a.23.23,0,0,1-.22.2l-1,0a.22.22,0,0,1-.2-.22h0a.94.94,0,0,0-1-.94.91.91,0,0,0-1,.79,14,14,0,0,0-.12,2.9.92.92,0,0,0,1,.87,1,1,0,0,0,1-.85h0a.22.22,0,0,1,.22-.21l1,0a.21.21,0,0,1,.2.22Z'
          fill='#fff'
        />
        <path
          d='M237,24.52a12.78,12.78,0,0,1-.24,2.89,2.47,2.47,0,0,1-4.92-.41,13.33,13.33,0,0,1,.24-2.89,2.47,2.47,0,0,1,4.92.41Zm-1.7,2.77a13.43,13.43,0,0,0,.25-2.9.94.94,0,0,0-.94-.91.92.92,0,0,0-1.07.75,13.38,13.38,0,0,0-.24,2.89,1,1,0,0,0,2,.17Z'
          fill='#fff'
        />
        <path
          d='M243.09,27.78a.07.07,0,0,0,.13,0l1.68-4.37a.19.19,0,0,1,.24-.14l1.51.19a.21.21,0,0,1,.18.24l-.89,6.8a.21.21,0,0,1-.23.18l-.9-.12a.2.2,0,0,1-.18-.23l.5-3.89c0-.06-.06-.09-.09,0l-1.56,3.8a.2.2,0,0,1-.24.14l-.82-.1a.22.22,0,0,1-.19-.21L241.71,26c0-.06-.08-.05-.09,0l-.51,3.89a.21.21,0,0,1-.23.18l-.91-.12a.22.22,0,0,1-.18-.24l.89-6.8a.21.21,0,0,1,.23-.18l1.53.2a.21.21,0,0,1,.19.21Z'
          fill='#fff'
        />
      </g>
      <g>
        <path
          d='M211.28,200.34c3.06,0,5.23,2.46,5.61,5.44a27.58,27.58,0,0,1,.07,6.42c-.31,3-2.43,5.53-5.49,5.57l-5.23.06a.5.5,0,0,1-.5-.5l-.18-16.43a.49.49,0,0,1,.49-.5Zm-2,3.51a.23.23,0,0,0-.24.25l.11,10a.22.22,0,0,0,.25.24l2,0a2.11,2.11,0,0,0,2-2,27.58,27.58,0,0,0-.07-6.42,2.12,2.12,0,0,0-2.06-2Z'
          fill='#fff'
        />
        <path
          d='M235.71,212.27a5.91,5.91,0,0,1-11.8.13l-.13-11.7a.51.51,0,0,1,.49-.51l2.52,0a.49.49,0,0,1,.5.49l.13,11.7a2.11,2.11,0,0,0,2.39,2c1.52,0,2.4-.93,2.39-2l-.13-11.7a.51.51,0,0,1,.49-.51l2.52,0a.5.5,0,0,1,.5.5Z'
          fill='#fff'
        />
        <path
          d='M158.47,230.8a5.23,5.23,0,0,1,.12,10.46l-2.24,0a.25.25,0,0,0-.25.26l.07,6.22a.5.5,0,0,1-.49.5l-2.49,0a.51.51,0,0,1-.51-.49l-.18-16.43a.51.51,0,0,1,.49-.51Zm-2.2,3.51a.24.24,0,0,0-.25.25l0,3a.24.24,0,0,0,.25.25l2.24,0a1.74,1.74,0,1,0,0-3.48Z'
          fill='#fff'
        />
        <path
          d='M173.65,247.57a.5.5,0,0,1-.49.5l-2.49,0a.5.5,0,0,1-.5-.5L170,231.17a.5.5,0,0,1,.49-.5l2.5,0a.5.5,0,0,1,.5.49Z'
          fill='#fff'
        />
        <path
          d='M184.59,244.21a.22.22,0,0,0,.25.24l5.9-.06a.5.5,0,0,1,.5.49l0,2.49a.49.49,0,0,1-.49.5l-9.14.11a.5.5,0,0,1-.5-.5L181,231.05a.49.49,0,0,1,.49-.5l9-.1a.49.49,0,0,1,.5.49l0,2.49a.5.5,0,0,1-.49.5l-5.81.07a.23.23,0,0,0-.24.25l0,3a.23.23,0,0,0,.25.24l5.6-.06a.51.51,0,0,1,.51.49l0,2.49a.51.51,0,0,1-.5.51l-5.6.06a.24.24,0,0,0-.25.25Z'
          fill='#fff'
        />
        <path
          d='M203.83,230.3c3.06,0,5.23,2.46,5.62,5.44a28.14,28.14,0,0,1,.07,6.42c-.32,3-2.43,5.53-5.49,5.57l-5.23.06a.51.51,0,0,1-.51-.5l-.18-16.43a.5.5,0,0,1,.49-.5Zm-2,3.51a.24.24,0,0,0-.25.25l.11,10a.23.23,0,0,0,.25.24l2,0a2.12,2.12,0,0,0,2-2,27.58,27.58,0,0,0-.08-6.42,2.11,2.11,0,0,0-2.06-2Z'
          fill='#fff'
        />
        <path
          d='M238,235a4.85,4.85,0,0,1-1.23,3.15.28.28,0,0,0,0,.45,4.82,4.82,0,0,1,1.5,3.37c0,3.26-2,5.4-5,5.43l-5.88.07a.5.5,0,0,1-.5-.49l-.18-16.44a.49.49,0,0,1,.49-.5L233,230A4.94,4.94,0,0,1,238,235Zm-5,1.67a1.67,1.67,0,0,0,1.63-1.63,1.65,1.65,0,0,0-1.66-1.58l-2.59,0a.24.24,0,0,0-.25.25l0,2.72a.23.23,0,0,0,.25.24Zm-2.55,3.52a.22.22,0,0,0-.24.25l0,3.26a.24.24,0,0,0,.25.25l2.59,0a1.88,1.88,0,0,0,0-3.76Z'
          fill='#fff'
        />
        <path
          d='M248.51,243.5a.24.24,0,0,0,.25.25l5.23-.06a.49.49,0,0,1,.5.49l0,2.49a.5.5,0,0,1-.49.5l-8.47.1a.5.5,0,0,1-.5-.5l-.18-16.43a.49.49,0,0,1,.49-.5l2.49,0a.5.5,0,0,1,.5.49Z'
          fill='#fff'
        />
        <path
          d='M264.25,243.32a.24.24,0,0,0,.25.25l5.9-.07a.5.5,0,0,1,.5.5l0,2.49a.5.5,0,0,1-.49.5l-9.14.1a.5.5,0,0,1-.5-.49l-.18-16.43a.5.5,0,0,1,.49-.51l9-.1a.5.5,0,0,1,.5.49l0,2.49a.5.5,0,0,1-.5.51l-5.8.06a.23.23,0,0,0-.24.25l0,3a.24.24,0,0,0,.25.25l5.6-.06a.49.49,0,0,1,.51.49l0,2.49a.5.5,0,0,1-.5.5l-5.6.06a.25.25,0,0,0-.25.26Z'
          fill='#fff'
        />
        <path
          d='M289.43,241.55a5.91,5.91,0,0,1-11.8.13L277.5,230a.49.49,0,0,1,.49-.5l2.52,0a.49.49,0,0,1,.5.49l.13,11.7a2.11,2.11,0,0,0,2.39,2c1.52,0,2.4-.93,2.39-2l-.13-11.7a.51.51,0,0,1,.49-.51l2.52,0a.49.49,0,0,1,.5.49Z'
          fill='#fff'
        />
      </g>
    </g>
  </svg>
);

LogoBouillon.propTypes = {
  primary: PropTypes.bool,
};
LogoBouillon.defaultProps = {
  primary: false,
};

export default LogoBouillon;
