// vendors
import React from 'react';
import PropTypes from 'prop-types';

// styles
import colors from '../../styles/colors';

// utils
import dayToConditionalString from '../../utils/dayToConditionalString';
import groupDays from '../../utils/groupDays';
import normalizeNumber from '../../utils/normalizeNumber';
import randomString from '../../utils/math/randomString';

const titleList = [
  'Il est ici!',
  'Là!',
  'Icitte!',
  'Par là-bas!',
  'Ici aussi!',
];

const Location = ({ inverted, place, indexTitle, openingHours }) => {
  const getDaysFromSelection = (days) => {
    const groupedDays = groupDays(days);
    return groupedDays.map((group) => dayToConditionalString(group));
  };

  return (
    <div
      css={`
        width: 100%;
        margin-bottom: 30px;
      `}
    >
      <h3
        css={`
          font-size: 2em;
          line-height: 1.1875em;
          margin-bottom: 0;
        `}
      >
        {titleList[indexTitle]}
      </h3>

      <h4
        css={`
          margin-top: 0;
          color: ${colors.white};
        `}
      >
        {place}
      </h4>

      <hr
        css={`
          background-color: ${inverted ? colors.secondary : colors.primary};
          height: 2px;
          border: 0;
          width: 7px;
          margin: 10px 0;
        `}
      />

      <p
        css={`
          margin: 0;
        `}
      >
        {openingHours.map((days) => (
          <span
            key={randomString()}
            css={`
              display: block;
            `}
          >
            {getDaysFromSelection(days.day)}
            {' de '}
            {days.hours.map((hour) => (
              <span key={randomString()}>
                {`${hour.fromTime.hour}h`}
                {hour.fromTime.minute
                  ? normalizeNumber(hour.fromTime.minute)
                  : '00'}
                {' à '}
                {`${hour.toTime.hour}h`}
                {hour.toTime.minute
                  ? normalizeNumber(hour.toTime.minute)
                  : '00'}
              </span>
            ))}
          </span>
        ))}
      </p>
    </div>
  );
};

Location.propTypes = {
  place: PropTypes.string.isRequired,
  indexTitle: PropTypes.number.isRequired,
  inverted: PropTypes.bool,
  openingHours: PropTypes.arrayOf(PropTypes.shape({})),
};

Location.defaultProps = {
  inverted: false,
  openingHours: [],
};

export default Location;
