// vendors
import styled, { css } from 'styled-components';

// styles
import { unorderListStyle } from '../../styles/global';
import { easing, speed } from '../../styles/animation';
import { cluster } from '../../styles/layouts';
import { fontWeights } from '../../styles/typography';
import colors from '../../styles/colors';

export const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;

  padding: 1em;

  background-color: ${({ $inverted }) =>
    $inverted ? colors.white : colors.secondary};

  ${({ $inverted }) =>
    $inverted &&
    css`
      li {
        color: ${colors.secondary};
      }
    `}
`;

export const Navigation = styled.nav`
  margin: auto;

  text-align: center;

  ${cluster('1em', 'center')}
`;

export const List = styled.ul`
  ${unorderListStyle}
`;

export const ListItem = styled.li`
  display: inline-block;

  color: ${colors.white};
  font-weight: ${fontWeights.regular};
  text-transform: uppercase;

  transform: translateZ(0);
  opacity: 1;

  transition-timing-function: ${easing.inQuint};
  transition-duration: ${speed.default};
  transition-property: opacity, transform;
`;
