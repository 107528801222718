// vendors
import styled from 'styled-components';
import { rem } from 'polished';

// styles
import { styles, utils } from '@pied-bleu/shared-ui';

// images
import LogoSVG from './Logo';

export const Container = styled.header`
  padding: 2em 0 5em;
`;

export const Wrapper = styled.div`
  position: relative;

  ${styles.layouts.center('30px', '1590px')}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const IntroWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 1em;

  color: ${styles.colors.primary};
  font-weight: ${styles.typography.fontWeights.bold};
  text-align: center;

  ${utils.breakpointsRange(
    [
      {
        prop: 'fontSize',
        sizes: [16, 27],
        bases: [16, 18],
        unit: 'rem',
      },
    ],
    [480, 1120]
  )};

  > * {
    margin: 0;
  }
`;

export const LogoWrapper = styled.div``;

export const Logo = styled(LogoSVG)`
  svg {
    width: 100%;
    max-width: ${rem(500, 18)};
  }
`;

export const TextWrapper = styled.div`
  margin: 2em 0;

  text-align: center;

  > * {
    margin: 0;

    ${styles.global.magnifyStyle}

    color: ${styles.colors.secondary};
  }
`;
