import breakpointsRange from './breakpointsRange';
import generateColorPalette from './generateColorPalette';
import mediaQuery from './mediaQuery';
import truncateString from './truncateString';
import randomString from './math/randomString';

export default {
  breakpointsRange,
  generateColorPalette,
  mediaQuery,
  randomString,
  truncateString,
};
