// vendors
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// styles
import colors from '../../styles/colors';
import { easing, speed } from '../../styles/animation';
import { unorderListStyle, linkStyle } from '../../styles/global';

const Item = styled.li`
  display: inline-block;
  margin-right: 2em;

  transform: translateZ(0);
  opacity: 1;

  transition-timing-function: ${easing.inQuint};
  transition-duration: ${speed.default};
  transition-property: opacity, transform;

  @media screen and (max-width: 991px) {
    margin-right: 0.8em;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Link = styled.a`
  ${linkStyle};

  font-weight: 300;
  text-transform: uppercase;

  && {
    color: ${({ isActive }) => isActive && colors.primary};
  }
`;

const List = styled.ul`
  ${unorderListStyle}
`;

const Container = styled.div`
  position: relative;
  z-index: 100;

  padding: 0.7em 30px;

  font-size: 14px;

  background-color: ${({ $inverted }) =>
    $inverted ? colors.white : colors.secondary};

  ${({ $inverted }) =>
    $inverted &&
    css`
      a {
        color: ${colors.secondary};
      }
    `};

  @media screen and (max-width: 767px) {
    font-size: 12.6px;
  }
`;

const MetaNavigation = ({ inverted }) => (
  <Container $inverted={inverted}>
    <nav
      css={`
        margin: auto;
        text-align: center;
      `}
    >
      <List>
        <Item>
          <Link href='https://piedbleu.com/' style={{ transitionDelay: '0ms' }}>
            Le pied bleu
          </Link>
        </Item>

        <Item>
          <Link
            href='http://bouchonquebec.com/'
            style={{ transitionDelay: '100ms' }}
          >
            Le bouchon
          </Link>
        </Item>

        <Item>
          <Link
            href='http://lerenardetlachouette.com/'
            style={{ transitionDelay: '200ms' }}
          >
            Le renard et la chouette
          </Link>
        </Item>

        <Item>
          <Link
            href='https://leroiduboudin.com/'
            style={{ transitionDelay: '300ms' }}
          >
            Le roi du boudin
          </Link>
        </Item>

        <Item>
          <Link
            href='https://lebouillon.piedbleu.com'
            style={{ transitionDelay: '400ms' }}
            isActive
          >
            Le Bouillon
          </Link>
        </Item>
      </List>
    </nav>
  </Container>
);

MetaNavigation.propTypes = {
  inverted: PropTypes.bool,
};
MetaNavigation.defaultProps = {
  inverted: false,
};

export default MetaNavigation;
